import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// import { AudioDeviceModalComponent } from 'src/app/components/audio-device-modal/audio-device-modal.component';
import { DialpadComponent } from 'src/app/components/dialpad/dialpad.component';
import { RoundButtonComponent } from 'src/app/components/round-button/round-button.component';

@NgModule({
  declarations: [
    // AudioDeviceModalComponent,
    DialpadComponent,
    RoundButtonComponent,
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    // AudioDeviceModalComponent,
    DialpadComponent,
    RoundButtonComponent,
  ]
})
export class ComponentModule { }
