import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.scss'],
})
export class RoundButtonComponent implements OnInit {
  @Input() btn: ButtonI;
  constructor() { }

  ngOnInit() {}

}

export interface ButtonI {
  label: string;
  icon: string;
  slot: 'start' | 'end';
  colour: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'dark';
  event: (e: ButtonI) => void;
}
