import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { SmsConvo } from '../types';
import { DBService } from '../services/db.service';


@Injectable({
  providedIn: 'root'
})
export class MessageResolverService implements Resolve<SmsConvo> {

  constructor(
    private db: DBService
    ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    const collection = route.paramMap.get('collection');
    const sid = route.paramMap.get('sid');
    return await this.db.get(sid, collection) as SmsConvo;
  }
}
