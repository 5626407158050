import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

import { TwilioService } from 'src/app/services/twilio.service';
import { DialpadEvent } from 'src/app/types';

@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.scss'],
})
export class DialpadComponent implements OnInit {
  @Output() digitPressed = new EventEmitter<DialpadEvent>();
  @Output() dialPressed = new EventEmitter<boolean>();
  @Output() backPressed = new EventEmitter<boolean>();
  @Output() phoneNumber = new EventEmitter<string>();

  @Input() dialButton = false;
  @Input() backButton = false;

  public number = {
    raw: '',
    formatted: ''
  };

  public dialpadButtons = [
    {'1': '', '2': 'abc', '3': 'def'},
    {'4': 'ghi', '5': 'jkl', '6': 'mno'},
    {'7': 'pqrs', '8': 'tuv', '9': 'wxyz'},
    {'*': '', '0': '', '#': ''},
  ];

  constructor(
    private twilio: TwilioService
  ) { }

  ngOnInit() {}

  digitPressedEvent(e: any) {
    this.number.raw += e.key;

    const numberInput = new AsYouType('CA').input(this.number.raw);
    if (numberInput) {
      this.number.formatted = numberInput;
    }

    this.digitPressed.next({
      digit: e.key,
      num: parseInt(e.key, 10),
      formatted: this.number.formatted
    });
    this.setPhoneNumber(this.number.formatted);
  }

  dialPressedEvent() {
    this.dialPressed.next(true);
  }

  backPressedEvent() {
    this.backPressed.next(true);
  }

  clear() {
    this.number = {raw: '', formatted: ''};
    this.setPhoneNumber('');
  }

  setPhoneNumber(s: string) {
    this.phoneNumber.next(s);
  }

}
