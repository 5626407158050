import { Injectable } from '@angular/core';

import { AngularFirestore, QueryFn, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Call, Voicemail, SmsConvo } from '../types';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DBService {

  constructor(
    private db: AngularFirestore,
    private auth: AuthService
  ) { }

  private async accountDoc() {
    const n = await this.auth.clientCapabilities.toPromise();
    return this.db.collection('accounts').doc(n.accountSid);
  }

  private document(doc: AngularFirestoreDocument, collection: string, sid: string) {
    return doc.collection(collection).doc(sid);
  }
  private collection(doc: AngularFirestoreDocument, collection: string, query?: QueryFn) {
    return doc.collection(collection, query);
  }

  async get(sid: string, collection: 'calls'): Promise<Call>;
  async get(sid: string, collection: 'voicemails'): Promise<Voicemail>;
  async get(sid: string, collection: 'sms'): Promise<SmsConvo>;
  async get(sid: string, collection: string): Promise<Call | SmsConvo | Voicemail>;
    async get(sid: string, collection: string) {
    if (sid === '') { console.error(`Expecting SID, recieved: SID=${sid} collection=${collection}`); }
    const doc = this.document(await this.accountDoc(), collection, sid);
    const snap = await doc.get().toPromise();

    switch (collection) {
      case 'calls':
        return new Call(snap);
      case 'voicemails':
        return new Voicemail(snap);
      case 'sms':
        return new SmsConvo(snap);
      default:
        console.error(`Unrecognized collection ${collection}`, snap.data());
        return;
    }
  }

  async list(collection: 'calls', queryFn?: QueryFn): Promise<Call[]>;
  async list(collection: 'voicemails', queryFn?: QueryFn): Promise<Voicemail[]>;
  async list(collection: 'sms', queryFn?: QueryFn): Promise<SmsConvo[]>;
  async list(collection: string, queryFn?: QueryFn) {
    const coll = this.collection(await this.accountDoc(), collection, queryFn);
    const snap = await coll.get().toPromise();

    const a = [];
    snap.forEach(docSnap => {
      switch (collection) {
        case 'calls':
          a.push(new Call(docSnap));
          break;
        case 'voicemails':
          a.push(new Voicemail(docSnap));
          break;
        case 'sms':
          a.push(new SmsConvo(docSnap));
          break;
        default:
          console.error(`Unrecognized collection ${collection}`, snap.metadata);
          return;
      }
    });
    return a;
  }
}
