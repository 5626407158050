
export interface DialpadEvent {
    digit: string;
    num: number;
    formatted: string;
}

import {
    ClientCapabilities as clientCapabilities,
    Call as call,
    PhoneNumber as phoneNumber,
    Fax as fax,
    SmsConvo as smsConvo,
    SmsMessage as smsMessage,
    UserNode as userNode,
    UserPresence as userPresence,
    Voicemail as voicemail
} from '../../../shared/types/types';

export class Base {
    private ref: firebase.firestore.DocumentReference;
    public sid: string;
    constructor(
        private snap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) {
        this.ref = snap.ref;
        this.sid = this.ref.id;
        const data = snap.data();
        this.setup(data);
    }
    setup(data: firebase.firestore.DocumentData) {
    }

}

export interface UserPresence extends userPresence {
}

export class UserPresence extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.admin = data.admin;
        this.allowIncoming = data.allowIncoming;
        this.allowOutgoing = data.allowOutgoing;
        this.available = data.available;
        this.displayName = data.displayName;
        this.forwardNumber = data.forwardNumber;
        this.platform = data.platform;
        this.sid = data.sid;
        this.time = data.time;
    }

    write() {
        return {
            admin: this.admin,
            allowIncoming: this.allowIncoming,
            allowOutgoing: this.allowOutgoing,
            available: this.available,
            displayName: this.displayName,
            forwardNumber: this.forwardNumber,
            platform: this.platform,
            sid: this.sid,
            time: this.time,
        };
    }
}

export interface Call extends call {
    created: firebase.firestore.Timestamp;
}
export class Call extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.created = data.created;
        this.status = data.status;
        this.direction = data.direction;
        this.duration = data.duration;
        this.sid = data.sid;
        this.user = data.user;
        this.platform = data.platform;
        this.to = data.to;
        this.from = data.from;
    }

    write() {
        return {
            created: this.created,
            status: this.status,
            direction: this.direction,
            duration: this.duration,
            sid: this.sid,
            user: this.user,
            platform: this.platform,
            to: this.to,
            from: this.from
        };
    }
}

export interface PhoneNumber extends phoneNumber {}
export class PhoneNumber extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.sid = data.sid;
        this.friendlyName = data.friendlyName;
        this.number = data.number;
        this.capabilities = data.capabilities;
        this.postalCode  = data.postalCode;
        this.city = data.city;
        this.state = data.state;
        this.country = data.country;

        // permissions
        this.allowIncoming = data.allowIncoming;
        this.incomingUsers = data.incomingUsers;
        this.allowOutgoing = data.allowOutgoing;
        this.outgoingUsers = data.outgoingUsers;
        this.enableFax = data.enableFax;
    }

    write() {
        return {
            sid: this.sid,
            friendlyName: this.friendlyName,
            number: this.number,
            capabilities: this.capabilities,
            postalCode : this.postalCode,
            city: this.city,
            state: this.state,
            country: this.country,

            // permissions
            allowIncoming: this.allowIncoming,
            incomingUsers: this.incomingUsers,
            allowOutgoing: this.allowOutgoing,
            outgoingUsers: this.outgoingUsers,
            enableFax: this.enableFax,
        };
    }
}

export interface Fax extends fax {
    created: firebase.firestore.Timestamp;
}
export class Fax extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.created = data.created;
        this.downloadUrl = data.downloadUrl;
        this.from = data.from;
        this.numPages = data.numPages;
        this.originalDeleted = data.originalDeleted;
        this.remoteStationId = data.remoteStationId;
        this.sid = data.sid;
        this.status = data.status;
        this.to = data.to;
    }

    write() {
        return {
            created: this.created,
            downloadUrl: this.downloadUrl,
            from: this.from,
            numPages: this.numPages,
            originalDeleted: this.originalDeleted,
            remoteStationId: this.remoteStationId,
            sid: this.sid,
            status: this.status,
            to: this.to,
        };
    }
}

export interface SmsConvo extends smsConvo {
    created: firebase.firestore.Timestamp;
    last: firebase.firestore.Timestamp;
}
export class SmsConvo extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.callerId = data.callerId;
        this.count = data.count;
        this.created = data.created;
        this.from  = data.from;
        this.last = data.last;
        this.preview = data.preview;
        this.to = data.to;
    }

    write() {
        return {
            callerId: this.callerId,
            count: this.count,
            created: this.created,
            from : this.from,
            last: this.last,
            preview: this.preview,
            to: this.to
        };
    }
}

export interface SmsMessage extends smsMessage {
    created: firebase.firestore.Timestamp;
}
export class SmsMessage extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.body = data.body;
        this.created = data.created;
        this.from  = data.from;
        this.media = data.media;
        this.mms = data.mms;
        this.numMms = data.numMms;
        this.originalDeleted = data.originalDeleted;
        this.segments = data.segments;
        this.sid = data.sid;
        this.status = data.status;
        this.to = data.to;
    }

    write() {
        return {
            body: this.body,
            created: this.created,
            from : this.from,
            media: this.media,
            mms: this.mms,
            numMms: this.numMms,
            originalDeleted: this.originalDeleted,
            segments: this.segments,
            sid: this.sid,
            status: this.status,
            to: this.to,
        };
    }
}

export interface Voicemail extends voicemail {
    created: firebase.firestore.Timestamp;
}
export class Voicemail extends Base {
    setup(data: firebase.firestore.DocumentData) {
        this.callSid = data.callSid;
        this.created = data.created;
        this.downloadUrl = data.downloadUrl;
        this.duration = data.duration;
        this.from  = data.from;
        this.originalDeleted = data.originalDeleted;
        this.to = data.to;
    }

    write() {
        return {
            callSid: this.callSid,
            created: this.created,
            downloadUrl: this.downloadUrl,
            duration: this.duration,
            from : this.from,
            originalDeleted: this.originalDeleted,
            to: this.to,
        };
    }

    public print() {
        const s = JSON.stringify(this.write());
        console.log(s);
        return s;
    }
}

export interface UserNode extends userNode {}
export class UserNode extends Base {
}

export interface ClientCapabilities extends clientCapabilities {}
