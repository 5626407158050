import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy, } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import 'firebase/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { ComponentModule } from 'src/app/components/component.module';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      animated: true,
      rippleEffect: true,
      mode: 'md',
      hardwareBackButton: true,
      statusTap: true,
      // backButtonIcon: '',
      // backButtonText: '',
      // menuIcon: '',
      // menuType: '',
      spinner: 'bubbles',
      loadingSpinner: 'crescent',
      // refreshingIcon: '',
      // refreshingSpinner: 'dots',
      infiniteLoadingSpinner: 'circles',
      swipeBackEnabled: true,
      tabButtonLayout: 'icon-top',
      // navAnimation: 
      // actionSheetEnter

    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebase),
    ComponentModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
