import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Call } from '../types';
import { DBService } from '../services/db.service';

@Injectable({
  providedIn: 'root'
})
export class CallResolverService implements Resolve<Call> {

  constructor(
    private db: DBService
    ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    const sid = route.paramMap.get('sid');
    return this.db.get(sid, 'calls');
  }
}
