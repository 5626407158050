import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Storage } from '@ionic/storage';

import { timer, ReplaySubject } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { User } from 'firebase/app';
import * as firebase from 'firebase/app';

import { Observable, Subscription } from 'rxjs';

import { LogService } from 'src/app/services/log.service';
import { HttpService } from 'src/app/services/http.service';

import { ClientCapabilities, UserNode } from 'src/app/types';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public clientCapabilities: ReplaySubject<ClientCapabilities> = new ReplaySubject<ClientCapabilities>(1);
  public user: firebase.User;
  public authState: ReplaySubject<firebase.User> = new ReplaySubject<firebase.User>(1);

  private $t: Subscription;

  // private userNodeDoc: AngularFirestoreDocument<UserNode>;
  // private userNodeObs: Observable<UserNode>;
  // private userNode$: Subscription;
  // public userNode: ReplaySubject<UserNode> = new ReplaySubject<UserNode>(1);

  constructor(
    private router: Router,
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    private http: HttpService,
    private log: LogService,
    private storage: Storage
  ) {
    afAuth.authState.subscribe(async u => {
      this.log.debug('Auth state changed');
      this.log.debug(u);

      if (u == null) {
        this.user = null;
        this.router.navigate(['/login']);
        return;
      }

      this.user = u;
      this.authState.next(u);
      this.router.navigate(['/']);

      // await this.getUserNode(u);
      await this.getClientCapabilities();
    }, err => {
      console.log(err);
    });
  }


  async getClientCapabilities() {
    try {
      const l = await this.storage.ready();
      // check for existing capabilities in localstorage
      const c = await this.storage.get('client');
      console.log(`Got capabilities from storage`, c);


      let t = await this.user.getIdToken();
      this.http.setToken(t);
      if (c == null || c.expiry == null || c.expiry < Date.now()) {
        // if empty or expired then
        if (this.$t != null && !this.$t.closed) {
          this.$t.unsubscribe();
          this.$t = null;
        }
        this.$t = timer(0, 500 * 1000).pipe(
          map(async _ => {
            t = await this.user.getIdToken();
            this.http.setToken(t);
          }),
          flatMap(_ => this.http.post(`/client/token/`, '', 'application/json', t))
        ).subscribe({
          next: async (v: ClientCapabilities) => {
            this.log.debug(`Retreived phone capabilities for user ${this.user.uid}`);

            // save capabilities to localstorage
            v.authToken = t;
            this.clientCapabilities.next(v);
            this.http.setToken(t);

            await this.storage.set('client', v);
          },
          error: (err) => {
            this.log.error(`Failed to retrieve phone capabilities`, err);
          }
        });
      } else {
        this.clientCapabilities.next(c);
      }
    } catch (err) {
      this.log.error(`Failed to retrieve phone capabilities`, err);
    }
  }

  // private async getUserNode(u: firebase.User) {
  //   this.userNodeDoc = this.db.collection('users').doc<UserNode>(u.uid);
  //   this.userNodeObs = this.userNodeDoc.valueChanges();
  //   this.userNode$ = this.userNodeObs.subscribe(v => {
  //     this.userNode.next(v);
  //     console.log(v);
  //   },
  //   err => {
  //     console.log(err);
  //   });

  //   const v = (await this.userNodeDoc.get().toPromise()).data() as UserNode;
  //   this.userNode.next(v);
  //   console.log(v);
  // }

  async registerEmailPassword(email: string, pw: string) {
    try {
      const result = await this.afAuth.auth.createUserWithEmailAndPassword(email, pw);
      this.log.debug('User signed in: ');
      this.log.debug(result);
    } catch (err) {
      console.error(err);
      return;
    }
  }

  async emailPasswordLogin(email: string, pw: string, fn?: (user: firebase.User) => void) {
    try {

      const result = await this.afAuth.auth.signInWithEmailAndPassword(email, pw);
      this.log.debug('User signed in: ');
      this.log.debug(result);

      if (fn != null) {
        fn(result.user);
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }

  async logout() {
    this.afAuth.auth.signOut();
  }

}
