import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { AlertButton } from '@ionic/core';
import { Platform, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import * as Twilio from 'twilio-client';

import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { HttpService } from 'src/app/services/http.service';
import { take } from 'rxjs/operators';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  private device = Twilio.Device;
  public speakerDevices: Set<MediaDeviceInfo>;
  public ringtoneDevices: Set<MediaDeviceInfo>;

  public callParams: {
    from: string;
    to: string;
    callSid: string;
    muted: boolean;
  };
  public callState: 'connected' | 'disconnected' | 'ringing' | 'incoming' = 'disconnected';
  private token = '';
  private access = '';
  public enabled = false;

  private options = {
    debug: true,
    allowIncomingWhileBusy: false,
    appName: 'Fountain-by-Cleanflo.JS',
    appVersion: '0.0.1',
    // audioConstraints: true,
    // backoffMaxMs: 20000,
    // codecPreferences: [
    //   'pcmu', 'opus'
    // ],
    closeProtection: true,
    // dscp: true,
    // enableIceRestart: false,
    enableRingingState: true,
    fakeLocalDTMF: true,
    // iceServers: null,
    // maxAverageBitrate: null,
    // region: 'gll',
    // rtcConfiguration: null,
    // sounds: null,
    warnings: true
  };
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private androidPermissions: AndroidPermissions,
    private http: HttpService,
    private auth: AuthService,
    private log: LogService
  ) {
    this.loadingCtrl.create({
      message: 'Logging in...',
      spinner: 'bubbles',
      animated: true,
      backdropDismiss: false,
      keyboardClose: true,
    }).then(load => {
      load.present();
      this.auth.clientCapabilities.subscribe(async (c) => {
        console.log('setting up twilio service');
        this.token = c.clientToken;
        this.access = c.accessToken;

        this.setup(true);
        this.hideLoad();
      });
    });
  }

  async setup(force: boolean = false) {
    if (!this.enabled || force) {
      const device = await this.platform.ready();
      console.log(`setting up ${device} device`);
      if (device === 'cordova') {
        this.plugin();
      } else {
        this.browser();
      }

      this.enabled = true;
    }
  }

  private plugin() {
    window.Twilio.TwilioVoiceClient.error(this.error);
    window.Twilio.TwilioVoiceClient.clientinitialized(this.ready);
    window.Twilio.TwilioVoiceClient.callinvitereceived(this.inviteRecieved);
    window.Twilio.TwilioVoiceClient.callinvitecanceled(this.inviteCancelled);
    window.Twilio.TwilioVoiceClient.calldidconnect(this.connected);
    window.Twilio.TwilioVoiceClient.calldiddisconnect(this.disconnected);
    window.Twilio.TwilioVoiceClient.initialize(this.access);
    console.log('device setup');
  }

  private browser() {
    this.device = Twilio.Device;

    // Emitted when any device error occurs.
    // These may be errors in your request, your token, connection errors, or other application errors.
    this.device.on('error', this.error);

    // This is triggered when the connection to Twilio drops or the device's token is invalid/expired.
    // In either of these scenarios, the device cannot receive incoming connections or make outgoing connections.
    // If the token expires during an active connection the offline event will be fired, but the connection will not be terminated.
    this.device.on('offline', (device) => {
      console.log('offline');
      console.log(device);

      this.enabled = false;
    });

    this.device.on('ready', this.ready);

    // This is triggered when a connection is opened, whether initiated using .connect() or via an accepted incoming connection.
    this.device.on('connect', this.connected);

    // Fired any time a Connection is closed.
    // The handler function receives the Twilio.Connection object that was just closed as an argument.
    this.device.on('disconnect', this.disconnected);

    // This is triggered whenever an incoming connection from an outbound REST call or a TwiML <Dial> to this device is made.
    this.device.on('incoming', this.inviteRecieved);

    // This is triggered when an incoming connection is canceled by the caller before it is accepted by the Twilio Client device.
    this.device.on('cancel', this.inviteCancelled);

    this.device.setup(this.token, this.options);
    this.refreshDevices();
    console.log('device setup');
  }

  error = async (err) => {
    // possibly refresh JWT
    if (err.code != null && err.code === 31205) {
      this.auth.getClientCapabilities();
    }
    // possibly navigate back to the home page?

    this.enabled = false;
  }

  ready = async (device) => {
    console.log('ready');
    console.log(device);

    this.enabled = true;
    if (device != null) {
      this.device.audio.on('deviceChange', (audioDevice) => {
        console.log('Device changed');
        console.log(audioDevice);
        this.updateAllDevices();
      });
    }
  }

  connected = async (conn) => {
    console.log('connected');
    console.log(conn);

    const top = (await this.loadingCtrl.getTop());
    if (top != null) {
      top.dismiss();
    }

    if (this.platform.is('cordova')) {
      this.callParams = conn;
    } else {
      this.callParams = {
        to: conn.parameters.To,
        from: conn.parameters.From,
        callSid: conn.parameters.CallSid,
        muted: await this.isMuted()
      };
    }
    this.callState = 'connected';

    console.log(this.callParams);

    // navigate to the 'ongoing call page'
    this.ngZone.run(() => {
      this.router.navigate(['ongoing-call']);
    });
  }

  disconnected = async (connection) => {
    console.log('disconnected');
    console.log(connection);

    this.loadingCtrl.dismiss();

    this.callParams = null;
    this.callState = 'disconnected';

    // navigate back to the last page before call started
    this.ngZone.run(() => {
      this.router.navigate(['/home/phone']);
    });
  }

  inviteRecieved = async (connection) => {
    console.log('incoming');
    console.log(connection);

    this.callState = 'incoming';

    // navigate to the 'incoming call page'
    this.ngZone.run(() => {
      this.router.navigate(['incoming-call']);
    });
  }

  inviteCancelled = async (connection) => {
    console.log('cancel');
    console.log(connection);

    this.callState = 'disconnected';

    // navigate back to the last page before incoming call
    this.ngZone.run(() => {
      this.router.navigate(['/home/phone']);
    });
  }

  async refreshDevices() {
    // get the speaker and mic devices from the twilio object
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.updateAllDevices();
  }

  async updateAllDevices() {
    this.speakerDevices = this.device.audio.speakerDevices.get();
    this.ringtoneDevices =  this.device.audio.ringtoneDevices.get();
  }

  connection() {
    return this.device.activeConnection();
  }

  async call(to: string, callerId: string) {
    this.auth.authState.pipe(take(1)).subscribe({
      next: async u => {
        const req = {
          to,
          callerId,
          // the following values are used for authentication and UAC
          uid: u.uid,
          platform: await this.platform.ready(),
        };

        try {
          this.showLoad(`Calling ${to} from ${callerId}`);
          await this.setup();

          if (req.platform === 'cordova') {
            const result = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);
            if (!result.hasPermission) {
              const perm = await this.androidPermissions.requestPermissions([
                this.androidPermissions.PERMISSION.RECORD_AUDIO,
                this.androidPermissions.PERMISSION.MICROPHONE,
                this.androidPermissions.PERMISSION.CAPTURE_AUDIO_OUTPUT,
                this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS
              ]);
              // this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);
              if (perm.hasPermission) {
                console.log('sending call');
                window.Twilio.TwilioVoiceClient.call(this.access, req);
              } else {
                console.log('permissions not available!!!');
              }

            } else {
              console.log('sending call');
              window.Twilio.TwilioVoiceClient.call(this.access, req);
            }
            return;
          }

          const outgoingConnection = this.device.connect({
            To: JSON.stringify(req)
          });
          outgoingConnection.on('ringing', () => {
            console.log('Ringing...');
            this.callState = 'ringing';
          });
        } catch (err) {
          console.log('Failed to make call: ', err);
        }
      }
    });
  }

  async sendDigits(digits: string) {
    if (this.platform.is('cordova')) {
      window.Twilio.TwilioVoiceClient.sendDigits(digits);
      return;
    }

    this.connection().sendDigits(digits);
  }

  async disconnect() {
    if (this.platform.is('cordova')) {
      window.Twilio.TwilioVoiceClient.disconnect();
      return;
    }

    this.connection().disconnectAll();
  }

  async rejectInvite() {
    if (this.platform.is('cordova')) {
      window.Twilio.TwilioVoiceClient.rejectCallInvite();
      return;
    }

    this.connection().ignore();
  }

  async acceptInvite() {
    if (this.platform.is('cordova')) {
      window.Twilio.TwilioVoiceClient.acceptCallInvite();
      return;
    }

    this.connection().accept();
  }

  async speaker(t: boolean) {
    if (this.platform.is('cordova')) {
      window.Twilio.TwilioVoiceClient.setSpeaker(t);
      return;
    }


    //////// NAVIGATE TO AUDIO DEVICE PAGE
    this.router.navigate(['audio-device']);
    ////// MANAGE THE AUDIO DEVICE WITH A SERVICE

    // show the select speaker popup
    // const modal = await this.modalCtrl.create({
    //   component: 'AudioDeviceModalComponent',
    //   componentProps: {
    //     speakerDevices: this.speakerDevices,
    //     ringtoneDevices: this.ringtoneDevices
    //   }
    // });
    // modal.present();
    // const {data} = await modal.onDidDismiss();
    // const { speaker, ringer } = data;

    // if (speaker != null) {
    //   this.device.audio.speakerDevices.set(speaker);
    // }

    // if (ringer != null) {
    //   this.device.audio.ringtoneDevices.set(ringer);
    // }
  }

  async speakerTest() {
    this.device.audio.speakerDevices.test();
  }

  async ringtoneTest() {
    this.device.audio.ringtoneDevices.test();
  }

  async mute(t: boolean) {
    if (this.platform.is('cordova')) {
      t ? window.Twilio.TwilioVoiceClient.muteCall()
      : window.Twilio.TwilioVoiceClient.unmuteCall();
      return;
    }
    this.connection().mute(t);
  }

  async isMuted(): Promise<boolean> {
    if (this.platform.is('cordova')) {
      return window.Twilio.TwilioVoiceClient.isCallMuted();
    }

    return this.connection().isMuted();
  }

  async endCall() {
    console.log(`ending call that is in state: ${this.callState}`);

    this.showLoad(`Ending '${this.callState}' call....`);

    const s = this.http.post('/client/end-call/', JSON.stringify({
      callSid: this.callParams.callSid
    })).subscribe(async v => {
      console.log(v);
      this.hideLoad();

      s.unsubscribe();
    }, async err => {
      console.log(err);
      this.hideLoad();

      // send request to 'complete' the other leg of this call
      const conn = this.connection();
      switch (this.callState) {
        case 'incoming' || 'connected' || 'ringing':
          if (conn != null) {
            conn.disconnect();
          }
          this.disconnect();
          break;
        case 'disconnected': // The connection has been disconnected.
          console.log('connection already closed');
          break;
      }

      s.unsubscribe();
    });
  }

  // dismiss - allow user to dismiss the modal by clicking the backdrop
  // keyboard - close the keyboard if open when the modal is entered
  async showLoad(msg: string, dismiss?: boolean, keyboard?: boolean) {
    const load = await this.loadingCtrl.create({
      message: msg,
      spinner: 'bubbles',
      animated: true,
      backdropDismiss: dismiss || false,
      keyboardClose: keyboard || true,
    });
    load.present();
  }

  hideLoad() {
    this.topLoading().then(l => {
      l ? l.dismiss() : console.log('nothing to dismiss');
    });
  }

  async topLoading() {
    return this.loadingCtrl.getTop();
  }

  async showAlert(msg: string, buttons: AlertButton[], header?: string, sub?: string) {
    const alert = await this.alertCtrl.create({
      message: msg,
      header,
      subHeader: sub,
      backdropDismiss: true,
      keyboardClose: true,
      translucent: false,
      buttons
    });

    alert.present();
  }

  hideAlert() {
    this.alertCtrl.dismiss();
  }
}


  /*

  document.getElementById('get-devices').onclick = function() {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(updateAllDevices.bind(device));
    }

    speakerDevices.addEventListener('change', function() {
      var selectedDevices = [].slice.call(speakerDevices.children)
        .filter(function(node) { return node.selected; })
        .map(function(node) { return node.getAttribute('data-id'); });

      device.audio.speakerDevices.set(selectedDevices);
    });

    ringtoneDevices.addEventListener('change', function() {
      var selectedDevices = [].slice.call(ringtoneDevices.children)
        .filter(function(node) { return node.selected; })
        .map(function(node) { return node.getAttribute('data-id'); });

      device.audio.ringtoneDevices.set(selectedDevices);
    });

    function updateAllDevices() {
      updateDevices(speakerDevices, device.audio.speakerDevices.get());
      updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());


      // updateDevices(speakerDevices, );
      // updateDevices(ringtoneDevices, device);
    }

    function updateDevices(selectEl, selectedDevices) {
      selectEl.innerHTML = '';
      device.audio.availableOutputDevices.forEach(function(device, id) {
        var isActive = (selectedDevices.size === 0 && id === 'default');
        selectedDevices.forEach(function(device) {
          if (device.deviceId === id) { isActive = true; }
        });

        var option = document.createElement('option');
        option.label = device.label;
        option.setAttribute('data-id', id);
        if (isActive) {
          option.setAttribute('selected', 'selected');
        }
        selectEl.appendChild(option);
      });
    }

    */
