import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CallResolverService } from './resolvers/call-resolver.service';
import { MessageResolverService } from './resolvers/message-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'src/app/pages/tabs/tabs.module#TabsPageModule'
  },
  {
    path: 'login',
    loadChildren: 'src/app/pages/login/login.module#LoginPageModule'
  },
  {
    path: 'incoming-call',
    loadChildren: 'src/app/pages/phone/incoming-call/incoming-call.module#IncomingCallPageModule'
  },
  {
    path: 'ongoing-call',
    loadChildren: 'src/app/pages/phone/ongoing-call/ongoing-call.module#OngoingCallPageModule'
  },
  {
    path: 'call-log',
    loadChildren: 'src/app/pages/call-log/call-log.module#CallLogPageModule',
  },
  {
    path: 'call-detail/:sid',
    resolve: {
      call: CallResolverService
    },
    loadChildren: './pages/call-log/call-detail/call-detail.module#CallDetailPageModule'
  },
  {
    path: 'message-detail/:collection/:sid',
    resolve: {
      message: MessageResolverService
    },
    loadChildren: './pages/messages/message-detail/message-detail.module#MessageDetailPageModule'
  },
  {
    path: 'contacts',
    loadChildren: 'src/app/pages/contacts/contacts.module#ContactsPageModule'
  },
  {
    path: 'users',
    loadChildren: './pages/users/users.module#UsersPageModule'
  },
  {
    path: 'device-settings',
    loadChildren: './pages/settings/device-settings/device-settings.module#DeviceSettingsPageModule',
  },
  {
    path: 'audio-device',
    loadChildren: './pages/audio-device/audio-device.module#AudioDevicePageModule'
  },
  {
    path: 'account-settings',
    children: [
      {
        path: 'users',
        loadChildren: './pages/account-settings/users/users.module#UsersPageModule'
      },
      {
        path: 'phone-numbers',
        loadChildren: './pages/account-settings/phone-numbers/phone-numbers.module#PhoneNumbersPageModule',
      },
      {
        path: 'phone-numbers/purchase',
        loadChildren: './pages/account-settings/phone-numbers/purchase-phone-number/purchase-phone-number.module#PurchasePhoneNumberPageModule'
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
