import { Injectable, ComponentRef } from '@angular/core';
import { AlertOptions, LoadingOptions, ModalOptions } from '@ionic/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { PhonePage } from 'src/app/pages/phone/phone.page';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private item: HTMLIonAlertElement | HTMLIonLoadingElement | HTMLIonModalElement;

  constructor(
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) {
    this.alertCtrl.create();
  }

  debug(msg: any) {
    console.log(msg);
  }

  error(msg: string, err: any) {
    console.error(msg, err);
  }

  async toast(message: string, duration: number = 2500, close: boolean = false,
              position: 'top' | 'bottom' | 'middle' = 'bottom'): Promise<HTMLIonToastElement> {
    return this.toastCtrl.create({
      message,
      duration,
      showCloseButton: close,
      translucent: false,
      animated: true,
      color: 'dark',
      keyboardClose: false
    });
  }

  async create(el: 'alert', opts: AlertOptions): Promise<HTMLIonAlertElement>;
  async create(el: 'loader', opts: LoadingOptions): Promise<HTMLIonLoadingElement>;
  async create(el: 'modal' | 'loader' | 'alert', opts: ModalOptions):
                  Promise<HTMLIonAlertElement | HTMLIonLoadingElement | HTMLIonModalElement> {
    switch (el) {
      case 'alert':
        this.item = await this.alertCtrl.create(opts);
        break;
      case 'loader':
        this.item = await this.loadCtrl.create(opts);
        break;
      case 'modal':
        this.item = await this.modalCtrl.create(opts);
        break;
    }

    return this.item;
  }

  async show() {
    this.item.present();
  }

  async hide() {
    this.item.dismiss();
  }


}
