import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private bearerToken = '';

  constructor(
    private http: HttpClient
  ) { }

  setToken(t: string) {
    this.bearerToken = t;
  }

      // PROD: https://us-central1-fountain-by-cleanflo.cloudfunctions.net/
      // DEV: http://localhost:5001/fountain-by-cleanflo/us-central1/
  post(endpoint: string, body: string, responseType?: string, token?: string) {
    // const u = `http://localhost:8083/fountain-by-cleanflo/us-central1/api`;
    const u = `https://us-central1-fountain-by-cleanflo.cloudfunctions.net/api`;
    return this.http.post(
      u + endpoint, body,
      {
        headers: {
          Authorization: `Bearer ${token || this.bearerToken}`,
          'Content-Type': responseType ? responseType : 'application/json'
        }
      });
  }
}
