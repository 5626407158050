// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBpiVZI9OJI4x-T0VDUHXZhK5etKCg0944',
    authDomain: 'fountain-by-cleanflo.firebaseapp.com',
    databaseURL: 'https://fountain-by-cleanflo.firebaseio.com',
    projectId: 'fountain-by-cleanflo',
    storageBucket: 'fountain-by-cleanflo.appspot.com',
    messagingSenderId: '859077037290',
    appId: '1:859077037290:web:d6c9c5c07a313e21e91b93',
    measurementId: "G-2DD7NSDEBY"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
